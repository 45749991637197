.dialogPaper {
  background-color: transparent;
  max-width: 866px;
  min-width: 866px;
  box-shadow: none;
}

.content {
  display: flex;
  min-width: 600px;
  flex-direction: row;
  padding: 24px;
  position: relative;
  align-items: center;
  height: 240px;
  @media (--viewport-md) {
    flex-direction: column;
    min-width: unset;
    align-items: center;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: 20px;
}

.logo {
  margin-left: 12px;
  width: 216px;
  min-width: 216px;
  height: 174px;
  min-height: 174px;
  position: relative;
  flex: 0 0 124px;
  margin-right: 24px;
  @media (--viewport-md) {
    margin-bottom: 24px;
    margin-right: 0;
  }
}

.details {
  max-width: 356px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 16px);
  margin-top: 8px;
}

.price {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  justify-content: space-between;
  padding-right: 8px;
  min-width: 150px;
  max-width: 150px;
  margin-bottom: 10px;
  height: calc(100% - 26px);
  .expiry {
    margin-bottom: auto;
    margin-top: 8px;
    opacity: 0.6;
  }
}

.upTo {
  padding-right: 4px;
}

.brand {
  margin-bottom: 8px;
}

.categoryName {
  color: var(--palette-common-grey-400);
  background: var(--palette-common-grey-150);
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.claimButton {
  margin-top: 12px;

  @media (--viewport-md) {
    width: 100%;
    margin-top: 24px;
  }
}

.topPrice {
  display: flex;
  align-items: flex-end;
}

.offText {
  margin-left: 4px;
}

.description {
  opacity: 0.6;
  overflow: auto;
}
