.dialogPaper {
  background-color: var(--palette-common-neutral-black);
  width: 1116px;
  min-width: 1116px;
  height: 668px;
  @media (--viewport-md) {
    height: 100%;
    width: 100%;
    min-width: 100%;
    background-color: var(--palette-common-neutral-white);
  }
}

.dialogTitle {
  color: var(--palette-text-contrast);
  display: flex;
  @media (--viewport-md) {
    color: var(--palette-text-primary);
  }
}

.dialogContent {
  overflow: hidden;
  padding: 0;
  display: flex;
  padding-top: 24px !important;
  @media (--viewport-md) {
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
  }
}

.secondaryButton {
  color: var(--palette-text-contrast);
  border-color: var(--palette-text-contrast);
}

.actionContainer {
  justify-content: flex-start;
  @media (--viewport-md) {
    border-top: 1px solid var(--palette-common-grey-200);
  }
}

.leftContainer {
  display: flex;
  padding-left: 24px;
  min-width: 296px;
  flex-wrap: wrap;
  margin-right: 24px;
  max-width: 296px;
  @media (--viewport-md) {
    display: none;
  }
}

.sidePageContainer {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidePage {
  width: 178px;
  max-height: 251px;
  margin-bottom: 16px;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  div {
    display: none;
  }
}

.loadingSidePage {
  width: 178px;
  max-height: 251px;
  min-height: 251px;
  margin-bottom: 16px;
  background-color: var(--palette-common-neutral-white);
  cursor: pointer;
  div {
    display: none;
  }
}

.documentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mainPageContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  overflow: auto;
}

.mainPagesContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 980px;
  overflow: auto;
  flex-direction: column;
  @media (--viewport-md) {
    height: unset;
  }
}

.mainPage {
  width: 690px;
  height: 960px;
  max-height: 960px;
  border-radius: 2px;
  overflow: hidden;
  div {
    display: none;
  }
  canvas {
    height: 960px !important;
  }
  @media (--viewport-md) {
    width: 100%;
    height: unset;
    canvas {
      height: unset !important;
    }
  }
}

.loadingMainPage {
  width: 700px;
  height: 980px;
  max-height: 1028px;
  background-color: var(--palette-common-neutral-white);

  div {
    display: none;
  }
  @media (--viewport-md) {
    width: 100%;
    height: unset;
  }
}

.documentContainer {
  display: flex;
  height: 960px;
  @media (--viewport-md) {
    margin-top: 20px;

    height: unset;
  }
}

.colorContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.color {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkIcon {
  color: var(--palette-common-neutral-white);
}

.imageContainer {
  position: relative;
  margin-top: 12px;
  border-radius: 2px;
  overflow: hidden;
  cursor: pointer;
  height: 162px;
}

.templateImage {
  width: 116px;
  height: 162px;
  cursor: pointer;
}

.templateItem {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selectedTemplate {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  .selectedIcon {
    margin: auto;
    margin-top: 65px;
    color: var(--palette-primary-main);
    font-size: 32px;
  }
  .selectedBar {
    height: 8px;
    width: 100%;
    align-self: flex-end;
    background-color: var(--palette-primary-main);
  }
}

.mobileColorRoot {
  display: none;
  @media (--viewport-md) {
    display: flex;
    position: sticky;
    flex-direction: column;
    bottom: 0;
    align-items: center;
    padding-top: 16px;
    padding-bottom: 16px;
    border-top: 1px solid var(--palette-common-grey-200);
    margin-left: -16px;
    width: calc(100% + 32px);
  }
}

.mobileColorContainer {
  margin-top: 16px;
  display: flex;
}

.templateTitle {
  display: none;
  @media (--viewport-md) {
    display: inline;
    margin-top: 12px;
  }
}

.actionButton {
  @media (--viewport-md) {
    flex: 1;
  }
}

.cancelButton {
  color: var(--palette-text-contrast);
  border-color: var(--palette-common-grey-400);
  @media (--viewport-md) {
    flex: 1;
    color: var(--palette-text-primary);
  }
}

.tabs {
  display: none;
  @media (--viewport-md) {
    display: block;
  }

  button {
    border-bottom: 1px solid var(--palette-common-grey-200);
  }

  .selectedTab {
    color: var(--palette-text-primary);
  }
  :global {
    .MuiButtonBase-root {
      line-height: 44px;
      opacity: 1;
      text-transform: none;
    }
    .MuiTabs-indicator {
      height: 4px;
      border-radius: var(--shape-borderRadius);
      background-color: var(--palette-text-primary);
    }
    a.Mui-disabled {
      color: var(--palette-action-disabled);
    }
  }
}

.loadingPdf {
  width: 100%;
  height: 900px;
  min-height: 900px;
  background-color: var(--palette-common-grey-400);
  transform: scale(1, 1);
  @media (--viewport-md) {
    width: 100%;
    height: 100%;
  }
}

.pdfContainer {
  display: flex;
  flex-direction: column;
  padding-right: 76px;
  width: 776px;
  overflow: auto;
  height: 100%;
  margin-left: auto;
  @media (--viewport-md) {
    width: 100%;
    padding-right: 0px;
  }
}

.pageNumberContainer {
  position: sticky;
  bottom: 32px;
  display: flex;
  align-items: center;
  background-color: var(--palette-common-grey-800);
  padding: 4px 8px;
  height: 36px;
  border-radius: 18px;
  justify-content: space-between;
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.chevronIcon {
  color: var(--palette-text-contrast);
  font-size: 20px;
  cursor: pointer;
}

.disabledChevronIcon {
  color: var(--palette-common-grey-400);
  cursor: default;
}
