.card {
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 24px;

  .text {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }

  .center {
    justify-content: center;
  }

  .row {
    display: flex;
    flex-direction: row;
    color: var(--palette-text-secondary);
    align-items: center;
  }
}
