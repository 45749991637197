.container {
  // border: 2px solid var(--palette-common-grey-150);
  // border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  padding-right: 12px;
  width: 100%;
  height: 104px;
}

.icon {
  color: var(--palette-common-neutral-white);
  font-size: 64px;
  margin-right: 16px;
}

.title {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
}

.button {
  margin-left: auto;
}
