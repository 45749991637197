.dialogPaper {
  background-color: var(--palette-common-grey-800);
  width: 1116px;
  min-width: 1116px;
  height: 668px;
  @media (--viewport-sm) {
    background-color: var(--palette-common-neutral-white);
    width: 100%;
    min-width: 100%;
    height: 100%;
  }
}

.dialogTitle {
  color: var(--palette-text-contrast);
  @media (--viewport-sm) {
    color: var(--palette-text-primary);
  }
}

.dialogContent {
  overflow: hidden;
  padding: 0;
}

.secondaryButton {
  color: var(--palette-text-contrast);
  border-color: var(--palette-text-contrast);
}

.actionContainer {
  justify-content: flex-start;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding-right: 42px;
  width: 254px;
  padding-left: 24px;
  min-width: 254px;
  @media (--viewport-sm) {
    background-color: var(--palette-common-neutral-white);
    width: 100%;
    min-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.sidePageContainer {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (--viewport-sm) {
    margin-bottom: 4px;
  }
}

.pageIndicator {
  border-bottom: 6px solid var(--palette-primary-main);
}

.sidePage {
  width: 178px;
  max-height: 251px;
  margin-bottom: 16px;
  cursor: pointer;
  border-radius: 2px;
  overflow: hidden;
  div {
    display: none;
  }
  @media (--viewport-sm) {
    max-height: unset;
    width: 100%;
    margin-bottom: 0px;
  }
}

.loadingSidePage {
  width: 178px;
  max-height: 251px;
  min-height: 251px;
  margin-bottom: 16px;
  background-color: var(--palette-common-neutral-white);
  cursor: pointer;
  div {
    display: none;
  }
}

.mainPagesContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.mainPageContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;

  @media (--viewport-sm) {
    display: none;
  }
}

.mainPage {
  width: 727px;
  height: 1028px;
  max-height: 1028px;
  border-radius: 2px;
  overflow: hidden;
  div {
    display: none;
  }
}

.loadingMainPage {
  width: 727px;
  height: 1028px;
  max-height: 1028px;
  background-color: var(--palette-common-neutral-white);

  div {
    display: none;
  }
}

.documentContainer {
  display: flex;
  height: 100%;
}

.downloadButton {
  @media (--viewport-sm) {
    width: 100%;
  }
}
