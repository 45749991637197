.title {
  margin-top: 32px;
  margin-bottom: 16px;
}

.card {
  display: flex;
  padding: 20px 16px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.cardIcon {
  width: 161px;
  min-width: 161px;
  height: 110px;
  margin-left: auto;
}

.cardDescription {
  margin-top: 4px;
  margin-bottom: 4px;
}

.cardDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.resourceTitleContainer {
  display: flex;
  margin-top: 16px;
  margin-bottom: 32px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.blogContainer {
  display: flex;
  justify-content: space-between;
  @media (--viewport-md) {
    flex-direction: column;
  }
}

.actionText {
  margin-top: 8px;
  color: var(--palette-primary-main);
  font-weight: 600;
  .arrowIcon {
    color: var(--palette-primary-main);
    margin-left: 6px;
    margin-bottom: -6px;
    font-size: 20px;
  }
}

.viewButton {
  white-space: nowrap;
  margin-left: 16px;
}
