.root {
  display: flex;
  flex-direction: column;
}

.rootCard {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0;
  overflow: hidden;
  padding: 24px;
  margin-bottom: 24px;
  margin-top: 16px;
}

.cardTitle {
  margin-bottom: 16px;
}

.cardSubTitle {
  margin-top: 24px;
  margin-bottom: 16px;
}

.mobileBlogContainer {
  display: flex;
  flex-direction: column;
}

.blogCard {
  margin-bottom: 16px;
}

.blogTitle {
  margin-bottom: 16px;
}

.pdgLoading {
  margin-top: 16px;
  margin-bottom: 24px;
}

.blogContainer {
  display: flex;
  width: 100%;
}

.blogLoading {
  width: 330px;
  height: 218px;
}

.blogLoading:first-child {
  margin-right: 16px;
}

.mobileBlogLoading {
  width: 100%;
  margin-bottom: 16px;
}

.swiper {
  width: 100%;
}

.extraResourceTitleLoading {
  margin-bottom: 16px;
  margin-top: 16px;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 64px;
  margin-bottom: 132px;
}

.emptyTitle {
  margin-top: 16px;
  margin-bottom: 4px;
}
