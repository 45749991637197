.root {
  width: 260px;
  height: 292px;
  display: flex;
  flex-direction: column;
  padding: 0;
  justify-content: flex-start;
  overflow: hidden;
  @media (--viewport-md) {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 12px;
    width: 100%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  @media (--viewport-md) {
    width: 100%;
  }
}

.bannerImage {
  width: 100%;
  height: 144px;
  min-height: 144px;
  position: relative;
  background-color: var(--palette-common-grey-100);
}

.content {
  padding: 12px;
  padding-top: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.bottomContainer {
  display: flex;
  justify-content: space-between;
}

.avatarsContainer {
  margin-top: auto;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  margin-left: 12px;
  width: max-content;
}

.enrolmentCount {
  margin-left: 6px;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  padding: 4px 0px;
  margin-left: -4px;
  margin-right: -4px;
}

.tooltipItem {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.tooltipItem:last-child {
  margin-bottom: 0;
}

.nickName {
  margin-left: 4px;
}
