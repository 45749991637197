.container {
  // border: 2px solid var(--palette-common-grey-150);
  border-radius: 4px;
  display: flex;
  padding: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  @media (--viewport-sm) {
    flex-direction: column;
  }
}

.image {
  width: 127px;
  min-width: 127px;
  height: 118px;
  min-height: 118px;
  border-radius: 4px;
  overflow: hidden;
  @media (--viewport-sm) {
    width: 100%;
    max-width: 279px;
    height: 231px;
    min-height: 231px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  @media (--viewport-sm) {
    margin-left: 0px;
    margin-top: 12px;
  }
}

.description {
  margin-top: 4px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: pre-wrap;
  @media (--viewport-sm) {
    margin-bottom: 4px;
  }
}

.buttonText {
  margin-top: 8px;
  @media (--viewport-sm) {
    padding-left: 0px;
    margin-left: 0px;
  }
}

.arrowIcon {
  margin-left: 4px;
  font-size: 20px;
  margin-bottom: -6px;
}
