.container {
  display: flex;
  flex-direction: column;
  margin-top: 0;
}

.title {
  margin-bottom: 8px;
}

.item {
  margin-top: 12px;
  height: 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.itemTitle {
  margin-left: 16px;
}

.selectedItemTitle {
  margin-left: 12px;
}

.selector {
  height: 28px;
  width: 4px;
  border-radius: 4px;
  background-color: var(--palette-text-primary);
}

.step {
  cursor: pointer;
}

.stepLabel {
  padding: 4px 0px;
}
.stepLoader {
  margin-bottom: 12px;
}
