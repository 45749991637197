.infoCard {
  min-height: 180px;
  z-index: 0;
  background: var(--palette-common-neutral-black);
  color: var(--palette-text-contrast);
  position: relative;

  span {
    z-index: -1;
  }

  padding: 16px 20px;
  @media (--viewport-md) {
    padding: 12px 8px;
  }

  :global {
    .MuiTableCell-root {
      color: var(--palette-text-contrast);
      padding-bottom: 8px;

      @media (--viewport-md) {
        padding: 0px 16px 12px 0px;
      }
    }
    .MuiTableCell-head {
      color: var(--palette-common-grey-200);
      font-weight: 600;
    }

    .MuiTableContainer-root {
      padding-top: 8px;
      margin-left: -12px;
      @media (--viewport-md) {
        margin-left: 0;
      }
    }
  }
}

.info {
  z-index: 1;
  height: 100%;
}

.bannerImage {
  border-radius: var(--shape-borderRadius);
  z-index: -1;
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 6.62%,
    rgba(255, 255, 255, 1) 95%
  );
  mask-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 6.62%,
    rgba(255, 255, 255, 1) 95%
  );
  background-color: var(--palette-common-grey-250);
  max-height: 180px;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  // object-fit: cover;
  @media (--viewport-md) {
    max-height: unset;
    height: 184px;
  }
}

.weather {
  position: absolute;
  text-align: right;
  color: var(--palette-common-grey-200);
  top: 0;
  right: 0;
  padding: 16px 20px;

  @media (--viewport-md) {
    bottom: 0;
    top: unset;
    padding: 16px;
  }

  time {
    padding-left: 4px;
  }
}

.share {
  padding: 0 4px;
  color: var(--palette-text-secondary);
  width: fit-content;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-right: auto;
  height: 36px;
  @media (--viewport-md) {
    margin-top: 14px;
  }
  h6 {
    line-height: 36px;
    padding-left: 12px;
    padding-right: 4px;

    span {
      color: var(--palette-text-primary);
      padding: 0 2px;
    }
  }
}

.superliteDestinationCountry {
  margin-top: 12px;
  margin-bottom: 16px;
  align-items: center;
  display: flex;
  @media (--viewport-md) {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.superLiteCountryText {
  font-family: Gilberto;
  font-size: 100px;
  font-weight: 400;
  line-height: 40px;
  color: var(--palette-common-neutral-white);
  letter-spacing: 2px;
  @media (--viewport-md) {
    font-size: 64px;
  }
}

.superliteCountryIcon {
  font-size: 32px;
  margin-left: 12px;
}

.bottomContainer {
  width: 100%;
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  align-items: flex-end;
  @media (--viewport-md) {
    flex-direction: column-reverse;
    margin-top: 0px;
    align-items: flex-start;
  }
}

.table {
  width: max-content;
  margin-bottom: -6px;
  @media (--viewport-md) {
    margin-bottom: 0;
  }
}

.shareSuperlite {
  margin-top: 24px;
}
