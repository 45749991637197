.root {
  width: 540px;
  height: 160px;
  display: flex;
  padding: 0;
  justify-content: flex-start;
  overflow: hidden;
  @media (--viewport-sm) {
    width: calc(100vw - 32px);
  }
}

.container {
  display: flex;
  height: 100%;
  flex: 1;
}

.bannerImage {
  width: 264px;
  min-width: 264px;
  height: 100%;
  position: relative;
  @media (--viewport-sm) {
    width: calc(50vw - 16px);
    max-width: calc(50vw - 16px);
    min-width: calc(50vw - 16px);
  }
  span {
    @media (--viewport-sm) {
      // background-color: var(--palette-common-grey-400) !important;
    }
    img {
      @media (--viewport-sm) {
        // height: unset !important;
        // object-fit: none !important;
        // min-height: unset !important;
      }
    }
  }
}

.content {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.progressStepsContainer {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 12px;
}

.stepsContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.playIcon {
  margin-right: 4px;
  font-size: 16px;
  color: var(--palette-common-grey-400);
}

.progress {
  height: 4px;
  border-radius: 4px;
}
