.dialogPaper {
  background-color: transparent;
  max-width: 343px;
  min-width: 343px;
  min-height: 573px;
  max-height: 573px;
  box-shadow: none;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 28px;
  position: relative;
  align-items: center;
  height: calc(100% - 56px);
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 343px;
}

.closeButton {
  position: absolute;
  top: 12px;
  right: 6px;
}

.logo {
  width: 279px;
  min-width: 279px;
  height: 115px;
  min-height: 115px;
  max-height: 115px;
  position: relative;
  flex: 0 0 124px;
  display: flex;
  span {
    margin: auto;
  }
}

.details {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  text-align: center;
}

.amountText {
  white-space: nowrap;
}

.price {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
  width: calc(100% - 16px);
  margin-bottom: 32px;
}

.expiry {
  margin-bottom: auto;
  margin-top: 8px;
  margin-left: 8px;
  opacity: 0.6;
  white-space: nowrap;
}

.physicalGoodExpiry {
  margin-top: 4px;
}

.upTo {
  padding-right: 4px;
}

.brand {
  margin-bottom: 8px;
}

.categoryName {
  color: var(--palette-common-grey-400);
  background: var(--palette-common-grey-150);
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.claimButton {
  width: 100%;
  margin-top: 16px;
}

.topPrice {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: wrap;
}

.offText {
  margin-left: 4px;
}

.description {
  opacity: 0.6;
  overflow: auto;
  max-height: 140px;
}
