.container {
  position: relative;

  border-radius: 8px;
  overflow: hidden;
  height: 218px;
  width: 322px;
  @media (--viewport-sm) {
    width: 100%;
    margin-bottom: 16px;
  }
}

.content {
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 138.61%);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  justify-content: flex-end;
  z-index: 2;
  padding: 16px;
}

.image {
  width: 322px;
  height: 218px;
  z-index: 1;
  position: absolute;
  @media (--viewport-sm) {
    width: 100%;
  }
}

.icon {
  position: absolute;
  cursor: pointer;
  right: 12px;
  top: 16px;
  color: var(--palette-common-neutral-white);
}

.label {
  margin-bottom: 4px;
}
