.item {
  width: 205px;
  height: 315px;
  padding: 16px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: transparent;
  cursor: pointer;
}

.listItem {
  @media (--viewport-sm) {
    margin: auto;
    width: 326px;
    height: 378px;
  }
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
}

.listImage {
  @media (--viewport-sm) {
    margin: auto;
    width: 326px;
    height: 378px;
  }
}

.logo {
  width: 136px;
  height: 57px;
  position: relative;
  margin-bottom: 12px;
  border-radius: 4px;
  overflow: hidden;
}

.listLogo {
  width: 250px;
  height: 90px;
}

.topContainer {
  display: flex;
  flex-direction: column;
  z-index: 2;
  align-items: center;
  text-align: center;
}

.categoryName {
  color: var(--palette-common-grey-450);
  background: var(--palette-common-grey-150);
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  margin: 8px auto;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  margin-top: auto;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 16px;
}

.bottomWithExpiry {
  margin-bottom: 8px;
}

.bottomInList {
  margin-bottom: 20px;
}

.inListWithExpiry {
  margin-bottom: 16px;
}

.inListWithPhysicalGood {
  margin-bottom: 24px;
}

.amountContainer {
  display: flex;
  align-items: flex-end;
}

.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.skeleton {
  margin-top: 8px;
  z-index: 2;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.amountText {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 173px;
  white-space: nowrap;
}

.offText {
  margin-left: 4px;
}

.expiryText {
  // min-height: 16px;
}
