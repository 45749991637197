.link {
  cursor: pointer;
}

.course {
  width: 100%;
  margin-bottom: 16px;
}

.row {
  margin: 16px 0;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  margin-top: 24px;
}

.view {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.viewAllSlider {
  display: flex;
  height: 310px;
  align-items: center;
  justify-content: center;
}

.newDealsContainer {
  :global {
    .swiperContainer > div {
      padding-bottom: 0px;
    }
  }
}

.blogCards {
  display: flex;
  gap: 16px;

  & > div {
    height: 160px;
    flex: 1;
  }

  @media (--viewport-md) {
    flex-direction: column;
  }
}
