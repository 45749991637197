.redirectIcon {
  font-size: 16px !important;
}

.redirectButton {
  // margin-top: 56px;
  padding: 0;
  color: var(--palette-text-contrast);
  align-self: flex-start;
}

.blogCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 12px 8px;
  height: 165px;
  @media (--viewport-md) {
    height: 130px;
  }
}
