.dialogContent {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.title {
  margin-top: 32px;
  padding: 0px 32px;
}

.description {
  margin-top: 16px;
  padding: 0px 64px;
}

.actionContainer {
  padding: 16px;
  padding-top: 8px;
  justify-content: center;
  padding-bottom: 48px;
}

.cancelButton {
  padding-left: 36px;
  padding-right: 36px;
}

.icon {
  margin-top: 18px;
}
