.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.horizontalContainer {
  flex-direction: row;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 16px; /* Increase/decrease this value for cross-browser compatibility */
  box-sizing: content-box;
}

.stepText {
  color: var(--palette-common-grey-450);
  white-space: nowrap;
  cursor: pointer;
}

.connectorContainer {
  display: flex;
  flex-direction: column;
  margin: 8px 0px;
  align-items: center;
}

.horizontalConnectorContainer {
  flex-direction: row;
  margin: 0px 8px;
}

.connectorLine {
  height: 22px;
  width: 1px;
  background-color: var(--palette-common-grey-300);
}

.horizontalConnectorLine {
  width: 22px;
  height: 1px;
}

.connectorDot {
  background-color: var(--palette-common-grey-300);
  width: 7px;
  height: 7px;
  border-radius: 50%;
}
