.paper {
  width: 636px;
  max-width: 636px;
}

.dialogContent {
  padding: 0px;
}

.image {
  width: 100%;
  height: 180px;
  background-color: grey;
}

.content {
  padding: 16px 24px;
}

.description {
  margin-top: 4px;
}

.actionContainer {
  padding: 24px;
  padding-top: 20px;
  border-top: 1px solid var(--palette-common-grey-150);
  justify-content: flex-start;
}

.cancelButton {
  padding-left: 36px;
  padding-right: 36px;
}

.textField {
  margin-top: 16px;
  margin-bottom: 24px;
}

.dialogSuccessContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 42px;
  text-align: center;
}

.successImage {
  margin-bottom: 36px;
  margin-top: 48px;
}

.successTitle {
  // font-weight: 600;
  margin-bottom: 16px;
}

.successDescription {
  // font-weight: 600;
  margin-bottom: 32px;
}

.successButton {
  width: max-content;
}
