.root {
  background-color: var(--palette-common-grey-900);
  height: 370px;
  min-height: 370px;
  width: 430px;
  min-width: 430px;
  margin-left: 20px;
  border-radius: 4px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 16px;
  @media (--viewport-lg) {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
}

.root::-webkit-scrollbar {
  width: 8px;
}

.root::-webkit-scrollbar-thumb {
  background: var(--palette-common-grey-200);
  border-radius: 4px;
}

.sectionText {
  padding: 8px 16px;
}

.stepContainer {
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
}

.stepContainer:hover {
  background-color: rgba(255, 255, 255, 0.16);
}

.checkIcon {
  color: var(--palette-common-vividMalachite-medium);
}

.checkIconPlaceHolder {
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
}

.durationText {
  margin-left: auto;
  margin-right: 8px;
}

.stepIcon {
  color: var(--palette-common-grey-400);
  margin-right: 10px;
}

.stepSeenIcon {
  color: var(--palette-common-neutral-white);
}

.selectedStep {
  background-color: rgba(255, 255, 255, 0.16);
}
