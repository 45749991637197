.card {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 2px solid var(--palette-common-grey-150);
  background-color: transparent;
  padding: 20px;
  transition: height 0.5s;
  overflow: hidden;
  margin-bottom: 16px;
}

.expandedCard {
  background-color: var(--palette-common-neutral-white);
}

.topContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

@keyframes reverseRotationIcon {
  from {
    rotate: 180deg;
  }
  to {
    rotate: 0deg;
  }
}

.icon {
  transform: rotate(90deg) !important;
  animation-name: reverseRotationIcon;
  animation-duration: 0.5s;
}

@keyframes rotationIcon {
  from {
    rotate: 180deg;
  }
  to {
    rotate: 0deg;
  }
}

.expandedIcon {
  animation-name: rotationIcon;
  animation-duration: 0.5s;
  transform: rotate(270deg) !important;
}

.hiddenAnswer {
  color: transparent;
  transition: color 0.5s;
}
