.root {
  position: sticky;
  top: 68px;
  background-color: var(--palette-common-grey-25);
  padding-bottom: 12px;
  padding-top: 16px;
  width: calc(100% + 24px);
  margin-left: -12px;
  padding-left: 12px;
}

.button {
  svg {
    margin-left: 16px;
  }
}

.buttonLoader {
}

.menu {
  @media (--viewport-sm) {
    width: 100%;
  }
}

.title {
  margin-bottom: 8px;
}

.numberContainer {
  width: 24px;
  height: 24px;
  min-width: 24px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: var(--palette-common-grey-350);
  align-items: center;
  justify-content: center;
  display: flex;
}

.selectedNumberContainer {
  background-color: var(--palette-primary-main);
}
