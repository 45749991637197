.title {
  margin-bottom: 8px;
}

.textField {
  margin-top: 16px;
  margin-bottom: 40px;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 64px;
}

.categoryTitle {
  margin-top: 8px;
  margin-bottom: 16px;
}

.tagsContainer {
  margin-top: 8px;
  margin-bottom: 24px;
  display: flex;
}

.tag {
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
}

.categoryLoader {
  width: 100px;
  height: 44px;
  border-radius: 24px;
  transform: scale(1, 1);
  margin-right: 8px;
  @media (--viewport-md) {
    margin-bottom: 8px;
  }
}

.titleLoader {
  margin-top: 16px;
  margin-bottom: 16px;
}

.faqLoader {
  height: 58px;
  width: 100%;
  margin-bottom: 16px;
}

.categoryContainer {
  display: flex;
  flex-wrap: wrap;
}
