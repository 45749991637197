.dialogContent {
  padding: 16px;
}

.description {
  margin-top: 24px;
}

.actionContainer {
  padding: 16px;
  padding-top: 12px;
}

.cancelButton {
  padding-left: 36px;
  padding-right: 36px;
}
