.dialogPaper {
  overflow: hidden;
  background: transparent;
  padding-top: 32px;
  box-shadow: none;
  @media (--viewport-md) {
    padding-top: inherit;
  }
}
.dialogContent {
  padding: 12px;
  min-height: 360px;
  min-width: 360px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: var(--palette-common-neutral-white);
  border-radius: var(--shape-borderRadiusSmall);
  overflow: initial;
}
.bannerContainer {
  width: 320px;
  height: 320px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: -54px;
  @media (--viewport-md) {
    margin-top: 12px;
  }
}

.imageContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay {
  background-color: #00000066;
  color: var(--palette-common-grey-200);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  flex-direction: column;
  padding: 12px 16px 32px 16px;
  p {
    font-weight: 600;
  }
}

.division {
  font-family: Gilberto;
  font-size: 44px;
  line-height: 42px;
  color: var(--palette-text-contrast);
}
.destination {
  text-align: center;
  font-family: Gilberto;
  font-size: 110px;
  line-height: 56px;
  color: var(--palette-text-contrast);
  margin-top: 24px;
}

.bannerImage {
  z-index: 1;
}

.startsIn {
  margin-top: 12px;
}
.started {
  margin-top: 24px;
}

.flag {
  img {
    top: -0.1em !important;
    margin-left: 8px;
  }
}

.title {
  margin-top: 32px;
}

.globalLogo {
  position: absolute;
  bottom: 8px;
}

.countdownContainer {
  margin: 4px 0px;
  display: flex;
  flex-direction: row;
}

.countdown {
  color: var(--palette-text-contrast);
  width: 32px;
  height: 44px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #ffffff;
  backdrop-filter: blur(3px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
  border-radius: var(--shape-borderRadiusSmall);
  h6 {
    font-weight: 700;
  }
}

.actions {
  margin-top: 16px;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    margin-top: 44px;
  }
}

.shareButton {
  margin-top: 16px;
}

.logosContainer {
  position: relative;
  display: flex;
  height: 56px;
  width: 100%;
  @media (--viewport-md) {
    margin-top: 16px;
  }
}

.socialLogo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  height: 60px;
  width: 60px;
  display: flex;
  opacity: 0.15;
}

.facebook {
  background-image: url(/images/facebook.svg);
  transform: rotate(-25deg);
  bottom: 32px;
  left: 32px;
}

.instagram {
  transform: rotate(22deg);
  background-image: url(/images/instagram.svg);
  bottom: -12px;
  left: calc(50% - 32px);
}

.twitter {
  transform: rotate(-18deg);
  background-image: url(/images/twitter.svg);
  bottom: 40px;
  right: 16px;
}
